// src/utils/keyboardShortcuts.js
import { useHotkeys } from 'react-hotkeys-hook';

const useKeyboardShortcuts = (callbacks) => {
  useHotkeys('shift+cmd+k', callbacks.deleteTask);
  useHotkeys('option+up', callbacks.moveTaskUp);
  useHotkeys('option+down', callbacks.moveTaskDown);
  useHotkeys('cmd+n', callbacks.newTask);
  useHotkeys('enter', callbacks.newLine);
  useHotkeys('option+8', callbacks.makeSubTask);
};

export default useKeyboardShortcuts;
