import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskItem from './TaskItem';
import useKeyboardShortcuts from '../utils/keyboardShortcuts';

const TodoList = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const response = await fetch('https://todo-backend.rickpeyton.workers.dev/tasks'); // Adjust the URL if needed
    const data = await response.json();
    setTasks(data);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTasks(items);
    await updateTasks(items);
  };

  const updateTasks = async (updatedTasks) => {
    await fetch('https://todo-backend.rickpeyton.workers.dev/tasks', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedTasks),
    });
  };

  const deleteTask = async () => {
    console.log('Delete task');
  };

  const moveTaskUp = () => {
    console.log('Move task up');
  };

  const moveTaskDown = () => {
    console.log('Move task down');
  };

  const newTask = () => {
    console.log('New task');
  };

  const newLine = () => {
    console.log('New line');
  };

  const makeSubTask = () => {
    console.log('Make sub-task');
  };

  useKeyboardShortcuts({
    deleteTask,
    moveTaskUp,
    moveTaskDown,
    newTask,
    newLine,
    makeSubTask,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="tasks">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.map((task, index) => (
              <Draggable key={task.id} draggableId={task.id} index={index}>
                {(provided) => (
                  <TaskItem task={task} provided={provided} />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TodoList;
