// src/components/TaskItem.jsx
import React from 'react';

const TaskItem = ({ task, provided }) => {
  return (
    <div
      className="todo-item"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <input type="text" value={task.text} readOnly />
    </div>
  );
};

export default TaskItem;
